"use client"
import React, { useState } from 'react';
import { RenderProviders } from '@/components/presentational/tailwind/Auth/RenderProviders';
import { useSignInWithMagicLink, useSignInWithPassword, useSignInWithProvider } from '@/utils/react-query-hooks';
import { APP_DOMAIN } from '@/constants';
import Anchor from 'shared-ui/components/Anchor';
import { LoadingSpinner } from '@/components/presentational/tailwind/LoadingSpinner';
import Input from 'src/components/forms/Input';
import InvalidLoginError from '../components/InvalidLoginError';
import SuccessEmailLinkLogin from '../components/SuccessEmailLinkLogin';

export function Login() {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [isSuccessful, setIsSuccessful] = useState(false);
    function redirectToDashboard() {
        setIsSuccessful(true);
        window.location.href = `${process.env.NODE_ENV == 'production' ? 'https://' : 'http://'}${APP_DOMAIN}`;
    }
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showMagicLink, setShowMagicLink] = useState<boolean>(false);

    // Google Login
    const providerMutation = useSignInWithProvider();
    // Email and Password Login
    const passwordMutation = useSignInWithPassword({
        onSuccess: redirectToDashboard,
        onError: (error: any) => {
            setErrorMessage(error.message);
        }
    });
    // Magic Link Login
    const magicLinkMutation = useSignInWithMagicLink({
        onSuccess: () => {
            setSuccessMessage('A magic link has been sent to your email!');
        },
        onError: (error: any) => {
            setErrorMessage(error.message);
        },
    });

    return (
        <>
            <div className="space-y-2 text-center">
                <h1 className="text-2xl font-medium">Log back in</h1>
                <p className="mx-auto text-sm max-w-72 text-neutral-500">
                    Create your free account on OkGrow to start growing your TikTok today.
                </p>
            </div>

            {isSuccessful ? (
                <div className='items-center mx-auto my-12 text-center'>
                    <LoadingSpinner className="w-8 h-8 !mx-auto text-turquoise" />
                    <p className="mt-3 text-sm font-medium text-turquoise">Logging you in...</p>
                </div>
            ) : (
                    <>
                        <form className="mt-8 mb-5 space-y-4" onSubmit={(e) => {
                            e.preventDefault();
                            if (showMagicLink) {
                                magicLinkMutation.mutate({
                                    email: e.currentTarget.email.value,
                                });
                            } else {
                                passwordMutation.mutate({
                                    email: e.currentTarget.email.value,
                                    password: e.currentTarget.password.value,
                                });
                            }
                        }}
                        >

                            {errorMessage && (
                                <InvalidLoginError errorMessage={errorMessage} />
                            )}

                            {successMessage && (
                                <SuccessEmailLinkLogin successMessage={successMessage} />
                            )}

                            <Input id="email" label="Email Address" placeholder="Enter email address" name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={passwordMutation.isLoading}
                                autoComplete={'email'}
                                required
                                autoFocus
                            />
                            {!showMagicLink && (
                                <Input id="password" label="Password" placeholder="Enter password" type="password" name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    disabled={passwordMutation.isLoading}
                                    autoComplete={'new-password'}
                                    required
                                />
                            )}

                            <button className="relative w-full p-px rounded-full h-9 bg-btn-gradient shadow-btn-outline-stroke" type="submit">
                                <span className="inline-flex items-center justify-center font-medium text-white rounded-full bg-turquoise-medium size-full text-sm/8">
                                    {(passwordMutation.isLoading || magicLinkMutation.isLoading) ? (
                                        <div className='inline-flex items-center justify-center transition-all animate-fade-in'>
                                            <LoadingSpinner className="relative w-4 h-4 mr-2 text-white" />
                                            {showMagicLink ? "Sending magic link..." : "Logging in..."}
                                        </div>
                                    ) :
                                        <div className='inline-flex items-center justify-center animate-fade-in-up-short'>
                                            {showMagicLink ? "Send magic link" : "Log in"}
                                        </div>
                                    }
                                </span>
                            </button>

                            <div className="space-y-6">
                                <div className="relative leading-none text-center">
                                    <span className="relative z-10 inline-block px-3 font-medium bg-white text-neutral-400 text-xs/4">or</span>
                                    <div className="absolute left-0 w-full h-px -translate-y-1/2 bg-neutral-200 top-1/2"></div>
                                </div>

                                <button
                                    onClick={() => setShowMagicLink(!showMagicLink)}
                                    type="button"
                                    className="w-full transition-all duration-300 border rounded-full h-9 border-neutral-300 hover:shadow">
                                    <span className="text-sm font-medium text-neutral-900">
                                        {!showMagicLink ? "Log in using a magic link" : "Login using password"}
                                    </span>
                                </button>

                            </div>
                        </form>

                        {!showMagicLink && (
                            <RenderProviders
                                providers={['google']}
                                isLogin={true}
                                isLoading={providerMutation.isLoading}
                                onProviderLoginRequested={(provider) => {
                                    providerMutation.mutate({
                                        provider,
                                    });
                                }}
                            />
                        )}

                        <p className="mt-6 text-sm text-center text-neutral-500">
                            Don't have an account?{" "}
                            <Anchor href="/sign-up" className="underline underline-offset-4">
                                Get started for free
                            </Anchor>
                        </p>
                    </>
            )}
        </>
    );
}
