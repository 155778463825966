import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/16/solid";

const SuccessEmailLinkLogin = ({ successMessage }) => {

  const [isVisible, setIsVisible] = useState(true);
  if (!isVisible) return null;

  return (
    <div className="bg-green-lighter flex items-start text-[13px] font-medium border border-green/20 rounded-lg space-x-2 py-2 px-2.5 mt-8">
      <CheckIcon className="mt-1 size-4 shrink-0 text-green-dark" />
      <p className="text-green-dark">
        {successMessage || "A magic link has been sent to your email. Please check your inbox and click the link to log in."}
      </p>
      <button type="button" className="ml-auto" onClick={() => setIsVisible(false)}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="transition-all duration-300 hover:saturate-150 hover:contrast-150 hover:brightness-75">
          <g opacity="0.32">
            <path
              d="M11.3334 4.6665L4.66675 11.3332M4.66675 4.6665L11.3334 11.3332"
              stroke="#1A7F37"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </button>
    </div>
  );
};

export default SuccessEmailLinkLogin;
